import axios from 'axios'

/* SUMÁRIO
procuraGrandesEncomendasPorStatus: Busca as GRandes Encomendas por Status

atualizaGrandesEncomendas: Atualiza dados da encomendas

salvaObsGrandesEncomendas: Salva observação em grandes encomendas

reagendarGrandesEncomendas: Reagendar Grandes encomendas

procuraFiltroData: Procura dados pelo filtro de data
*/

const baseURL = '/grandesEntregas'

const grandesEntregasApi = axios.create({
  baseURL: baseURL,
})


export const procuraGrandesEncomendasPorStatus = async ({ queryKey }) => {
  // Busca as Grandes Encomendas por Status
  var value = {"data": queryKey[0]}

  // Busca dados no banco de dados Taon
  const res = await grandesEntregasApi.get('/procuraGrandesEncomendasPorStatus', {params: value})
  //console.log(res.data)
  return res.data
}

export const atualizaGrandesEncomendas = async (value) => {
  //Atualiza dados da encomendas

  const res = await grandesEntregasApi.post('/atualizaGrandesEncomendas', value)

  return res
}

export const atualizaEntregaGrandesEncomendas = async (value) => {
  //Atualiza dados de grandes encomendas para finalizada

  const res = await grandesEntregasApi.post('/atualizaEntregaGrandesEncomendas', value)

  return res
}

export const salvaObsGrandesEncomendas = async (value) => {
  //Salva observação em grandes encomendas

  const res = await grandesEntregasApi.post('/salvaObsGrandesEncomendas', value)

  return res
}

export const reagendarGrandesEncomendas = async (value) => {
  //Reagendar Grandes encomendas

  const res = await grandesEntregasApi.post('/reagendarGrandesEncomendas', value)

  return res
}

export const procuraFiltroData = async (value) => {
  //Procura dados pelo filtro de data

  const res = await grandesEntregasApi.post('/procuraFiltroData', value)

  return res
}

