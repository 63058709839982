export const cores = {
    roxo: '#6B4380',
    amarelo: '#f5dc61',
    verde:'rgb(66, 175, 60)',
    vermelho:'#c84333',
    laranja:'#f09226',
    cinzaClaro: 'rgba(0, 0, 0, 0.6)',
    cinzaEscuro: 'rgb(78, 78, 78)',
};

export default cores;