import React from 'react'
import ReactDOM from 'react-dom'
//import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
//import { createStore, applyMiddleware, compose } from 'redux'
//import thunk from 'redux-thunk'
import { AuthProvider } from './hooks/useAuth'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { ThemeCtxProvider } from '../src/utils/context/themeCtx'

//import reducers from './reducers'
import { QueryClient, QueryClientProvider } from 'react-query'

import App from './App'
const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
})

ReactDOM.render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<GoogleOAuthProvider clientId="608766930819-7hqmen7mp7dq6br1o0vfs7ort2rd2gjv.apps.googleusercontent.com">
				<AuthProvider>
					<ThemeCtxProvider>
						<App />
					</ThemeCtxProvider>
				</AuthProvider>
			</GoogleOAuthProvider>
		</QueryClientProvider>
	</BrowserRouter>,
	document.getElementById("root")
)
