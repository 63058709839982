import axios from 'axios'

/*
postAlteraTarifa: Altera a tarifa dos motoqueiros
postAtualizaTaxistas: Altera status dos motoqueiros
postAlteraFilaMa: Altera status da fila ma
postAlteraIconeVinculo: Altera icone do vinculo
postAlteraDinamicaChuva: Altera tarifa de dinamica de chuva
postProcuraTaxistas: Procura taxistas
*/
const baseURL = '/telaComando'
const telaComandoApi = axios.create({
  baseURL: baseURL,
})

export const postAlteraTarifa = async (value) => {
  //Altera a tarifa dos motoqueiros

  const res = await telaComandoApi.post('/alteraTarifa', value)

  return res
}

export const postAtualizaTaxistas = async (value) => {
  //Altera status dos motoqueiros

  const res = await telaComandoApi.post('/atualizaTaxistas', value)

  return res
}

export const postProcuraTaxistas = async (value) => {
  //Procura taxistas

  const res = await telaComandoApi.post('/procuraTaxistas', value)

  return res
}


export const postAlteraFilaMa = async (value) => {
  //Altera status da fila ma

  const res = await telaComandoApi.post('/alteraFilaMa', value)

  return res
}

export const postAlteraIconeVinculo = async (value) => {
  //Altera icone do vinculo

  const res = await telaComandoApi.post('/alteraIconeVinculo', value)

  return res
}

export const postAlteraDinamicaChuva = async (value) => {
  //Altera tarifa de dinamica de chuva

  const res = await telaComandoApi.post('/alteraDinamicaChuva', value)

  return res
}