import React, { useState } from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Button from '../../components/button/Button'
import './settings.scss'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getSettingsData, setSettingsData, setRobo } from '../../api'

const Settings = () => {
  const [selections, setselections] = useState({})
  const [statusRobo, setstatusRobo] = useState('desativado')

  const settingsData = useQuery('media', getSettingsData)
  const queryClient = useQueryClient()

  const setSettingsMutation = useMutation(setSettingsData, {
    onSuccess: () => {
      queryClient.invalidateQueries('media')
    },
  })

  const setRoboGrafico =  useMutation(setRobo, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        setstatusRobo(e.data.data.data)
        return true
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
      return true
    }
  });
  
  const hangleRobo = (status) =>{

    if(status === "ativa"){
      setRoboGrafico.mutate({"data":"ativa"})
    }else if(status === "desativa"){
      setRoboGrafico.mutate({"data":"desativada"})
    }
  }

  return (
    <div className="settings">
      <div className="settingsCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"}/>
        <Sidebar />
        <div className="main box">
          {
            <div style={{display:"flex"}}> 
              {
               statusRobo === 'desativado' ?  <Button onClick={() => {hangleRobo("ativa")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Ativar Boot Reserva"} /> :              <Button onClick={() => {hangleRobo("desativa")}} width={"170px"} color={"#ffffff"} background={"#c84333"} border={"#c84333"} text={"Desativar Boot Reserva"} />
              }
            </div>
          /*<div className="subOptions box">
            <div className="subOption">
              Gráfico
              <i className="material-icons-outlined">filter_list</i>
            </div>
            <div className="subOption">
              Mapas
              <i className="material-icons-outlined">filter_list</i>
            </div>
            <div className="subOption">
              Escala
              <i className="material-icons-outlined">filter_list</i>
            </div>
          </div>
          <div className="options box">
            <h3 className="top">Opções: Gráfico</h3>
            <div className="middle">
              <div className="option">
                <label className="optionTitle">Alarme média: </label>
                <div className="inputButton">
                  <input
                    type="Number"
                    placeholder={settingsData?.data?.alarmeMedia}
                    step="0.1"
                    onChange={(e) =>
                      setselections((prev) => {
                        return {
                          ...prev,
                          alarmeMedia: e.target.value,
                        }
                      })
                    }
                  />
                  <i
                    className="material-icons-outlined"
                    onClick={() => {
                      setSettingsMutation.mutate({
                        alarmeMedia: selections?.alarmeMedia,
                      })
                    }}
                  >
                    check
                  </i>
                </div>
              </div>
              <div className="option">
                <label className="optionTitle">Alarme pendência:</label>
                <div className="inputButton">
                  <input
                    name="alarmePend"
                    type="Number"
                    placeholder={settingsData?.data?.alarmePend}
                    onChange={(e) =>
                      setselections((prev) => {
                        return {
                          ...prev,
                          alarmePend: e.target.value,
                        }
                      })
                    }
                  />

                  <i
                    className="material-icons-outlined"
                    onClick={() => {
                      setSettingsMutation.mutate({
                        alarmePend: selections?.alarmePend,
                      })
                    }}
                  >
                    check
                  </i>
                </div>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </div>
  )
}

export default Settings
