import React, {useEffect, useState} from 'react'
import {useMutation, useQueryClient, useQuery} from 'react-query'
import { useOutletContext } from "react-router-dom";
import Icon from '@material-ui/core/Icon'
import { Oval } from  'react-loader-spinner'

import './grandesEncomendas.scss'

import axios from 'axios'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import CardList from '../../components/card/CardList/cardList'
import DateRangeFilter from '../../components/date/Date'
import PopUp from '../../components/popUp/PopUp'
import Button from '../../components/button/Button'

import {procuraGrandesEncomendasPorStatus, procuraFiltroData } from '../../api/grandesEntregasData'

const GrandesEncomendas = () => {

  const baseURL = '/grandesEntregas'
  const grandesEntregasApi = axios.create({
    baseURL: baseURL,
  })

  const queryClient = useQueryClient()

  const permissao = useOutletContext();

  const [loader, setLoader] = useState(false);

  const [atualiza, setAtualiza] = useState(false);
  const [selectFiltro, setSelectFiltro] = useState("emAndamento");

  const [popUpCalendario, setPopUpCalendario] = useState(false);

  const [user, setUser] = useState(false);

  const [finalizadas, setFinalizadas] = useState(false);
  const [dataFinalizadas, setDataFinalizadas] = useState(false);

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });
  
  const coletadasData = useQuery([{"status": "Coletado", "id_entregador":JSON.parse(localStorage.getItem("user")).name}], procuraGrandesEncomendasPorStatus, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })

  const coletasSolicidatasData = useQuery([{"status":"Aguardando"}], procuraGrandesEncomendasPorStatus, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })

  const coletasFinalizadasData = useQuery([{"status":"Finalizada", "id_entregador":JSON.parse(localStorage.getItem("user")).name}], procuraGrandesEncomendasPorStatus, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })

  const fetchData = async () => {

    try {
      coletadasData = await grandesEntregasApi.get('/procuraGrandesEncomendasPorStatus',{params: {"data": {"status":"Coletado"}}})
      coletasSolicidatasData = await grandesEntregasApi.get('/procuraGrandesEncomendasPorStatus', {params: {"data": {"status":"Aguardando",  "id_entregador":JSON.parse(localStorage.getItem("user")).name}}})
      coletasFinalizadasData = await grandesEntregasApi.get('/procuraGrandesEncomendasPorStatus', {params: {"data": {"status":"Finalizada", "id_entregador":JSON.parse(localStorage.getItem("user")).name}}})
    } catch (error) {
    } 
    setFinalizadas(false)
  }

  const lidarFiltro = async (filtro) => {
    if(filtro === 'emAndamento'){
      setSelectFiltro("emAndamento")

    }else if(filtro === 'coletadas'){
      setSelectFiltro("coletadas")
    }else if(filtro === 'finalizadas'){
      setSelectFiltro("finalizadas")
    }
  }

  const lidarDate = async () => {
    setLoader(true)

    var filtro
    if(selectionRange.startDate === selectionRange.endDate){
      console.log('Antes da alteração:', selectionRange.startDate, selectionRange.endDate);

      // Criar uma cópia da endDate para manipulação
      let newData = new Date(selectionRange.endDate);

      newData.setUTCHours(26);

      console.log('Depois da alteração:', selectionRange.startDate, selectionRange.endDate);
      filtro = {
        "status": "Finalizada",
        "updatedAt": {$gte: selectionRange.startDate, $lt: newData},
        "id_entregador":JSON.parse(localStorage.getItem("user")).name
      }
    }else{
      filtro = {
        "status": "Finalizada",
        "updatedAt": {$gte: selectionRange.startDate, $lt: selectionRange.endDate},
        "id_entregador":JSON.parse(localStorage.getItem("user")).name
      }
    }

    filtroData.mutate(filtro)
  }

  const filtroData = useMutation(procuraFiltroData, {
    onSuccess: (e) => {
      queryClient.invalidateQueries();
      if (e.status === 201) {
        setFinalizadas(true)
        setDataFinalizadas(e.data)
        setLoader(false)
        setPopUpCalendario(false)
      }
    },

    onError: (e) => {
      setLoader(false)
      alert(e.response.data)
      setPopUpCalendario(false)
    },
  });
  
  useEffect(() => {

    setUser(JSON.parse(localStorage.getItem("user")).name)
  },[])

  useEffect(() => {

    if(atualiza === true){

      fetchData()
      
      setAtualiza(false)
    }
  },[setAtualiza, atualiza])

  return (
    <div className="paginaGrandesEncomendas">
      <div className="contGrandesEncomendas">
        <Navbar acesso={permissao} invisivel={false} select={"default"} selectList={"default"} selectOP={"default"}/>
        <Sidebar pagina={"Grandes Encomendas"} />
        <div className="content">

          <div className='botoes-filtro'>
            <div  className={`botao ${selectFiltro === "emAndamento" ? "ativo" : ""}`} onClick={()=>{lidarFiltro("emAndamento")}}> Coletas Solicitadas</div>
            <div  className={`botao ${selectFiltro === "coletadas" ? "ativo" : ""}`} onClick={()=>{lidarFiltro("coletadas")}}> Coletadas</div>
            <div  className={`botao ${selectFiltro === "finalizadas" ? "ativo" : ""}`} onClick={()=>{lidarFiltro("finalizadas")}}> Finalizadas</div>
          </div>


          {selectFiltro === 'emAndamento' &&
            <div className='cards'>
              <CardList user={user} titulo={"Coletas Solicitadas"} lista={coletasSolicidatasData.data} atualiza={setAtualiza} />
            </div>
          }

          {selectFiltro === 'coletadas' &&
            <div className='cards'>
              <CardList user={user} titulo={"Coletadas"} lista={coletadasData.data} atualiza={setAtualiza} />
            </div>
          }

          {selectFiltro === 'finalizadas' &&
            <div className='cards'>
              <div className='icone-calendario'>
                <Icon onClick={() => {setPopUpCalendario(true)}} style={{color:"rgb(29, 28, 28)", cursor:"pointer", fontSize:"35px"}} className="iconAssinaturaEntrega">calendar_month</Icon>
                <Icon onClick={() => {fetchData()}} style={{color:"rgb(29, 28, 28)", cursor:"pointer", fontSize:"35px"}} className="iconAssinaturaEntrega">sync</Icon>
              </div>
              {finalizadas === false &&
                <CardList user={user} titulo={"finalizadas"} lista={coletasFinalizadasData.data} atualiza={setAtualiza} />
              }
              {finalizadas !== false &&
                <CardList user={user} titulo={"finalizadas"} lista={dataFinalizadas.data} atualiza={setAtualiza} />
              }
            </div>
          }
        </div>
      </div>

      <PopUp trigger={popUpCalendario} setTrigger={setPopUpCalendario}>
        <div className='popUp-date'>
          {loader === true &&
            <div className='loader-grandes-encomendas'>
              <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
            </div>
          }

          <DateRangeFilter onDateChange={setSelectionRange}  />

          <div style={{display:"flex", justifyContent:"flex-end"}}>
            <Button onClick={() => {lidarDate()}} width={"100px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Filtrar"} />
          </div>
        </div>
      </PopUp>
    </div>
  )
}

export default GrandesEncomendas
