import React, { useContext } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import './app.scss'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import Users from './pages/users/Users'
import Settings from './pages/settings/Settings'
import GrandesEncomendas from './pages/grandesEncomendas/GrandesEncomendas'

import { ProtectedLayout } from './components/protectedRoutes/ProtectedLayout'

import { HomeLayout } from './components/protectedRoutes/HomeLayout'
import { ThemeCtx } from './utils/context/themeCtx'

import { ReactQueryDevtools } from 'react-query/devtools'

const App = () => {
  const { darkMode } = useContext(ThemeCtx)
  return (
    <div className="app">
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>

        <Route element={<ProtectedLayout autorizacoes={"geral"}  />}>
          <Route path="*" element={<Navigate replace to="/" />} />

          <Route path="/grandesEncomendas" element={<GrandesEncomendas />} />
          <Route path="/home" element={<Home />} />

          <Route path="/users" element={<Users />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App
