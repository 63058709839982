import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, Route, Routes, useNavigate } from "react-router-dom";

import './navbar.scss'

import Icon from '@material-ui/core/Icon'
import { useAuth } from '../../hooks/useAuth'
import { ThemeCtx } from '../../utils/context/themeCtx'
import PopUp from '../popUp/PopUp'
import TelaComando from '../telaComando/TelaComando';

import logo from "./logo.png";

const NavBar = (prop) => {

  const navigate = useNavigate();

  const { logout, user } = useAuth()

  return (
    <div className="navbar" style={{width: prop.width}}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/home" style={{ textDecoration: "none" }}>
            <img src={logo} alt="testsss" width={130} height={50} />
          </Link>
        </div>

        <div className="center">
          <div className="graficosSide">
            <i className="material-icons">local_shipping</i>
            <Link to="/grandesEncomendas" style={{ textDecoration: "none" }}>
              <div className='text-links'>Grandes Encomendas</div>
            </Link>
          </div>
        </div>

        <div className="items">
          <div className="item">
            <img className="avatar" src={user?.picture} alt="avatar" />
          </div>
          <div className="item" onClick={()=>{navigate('/settings')}}>
            <Icon className="icon">settings</Icon>
          </div>
          <div className="item" onClick={logout}>
            <Icon className="icon">logout</Icon>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavBar
