import './webCam.scss'
import React, {useState } from "react";
import Webcam from "react-webcam";
import Button from '../button/Button'

const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = useState(null);

  const [facingMode, setFacingMode] = useState('environment'); 

  const toggleFacingMode = React.useCallback(() => {
    setFacingMode(prevState => (prevState === 'user' ? 'environment' : 'user'));
  }, []);

  const videoConstraints = {
    facingMode: facingMode,
  };


  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    },
    [webcamRef]
  );

  const retake = () => {
      setImgSrc(null);
  };

  const salvar = () => {
      props.setImagem(imgSrc);
      props.setTrigger(false);
  };

  return (
    <>

      {imgSrc ? (
        <img className='capitura' src={imgSrc} alt="webcam" />  
          ) : (
              <Webcam
              className='capitura'
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
            />
      )}

      {imgSrc ? (
        <div style={{display: "flex", justifyContent:"flex-end", width:"100%"}}>
          <Button onClick={retake} width={"120px"} color={"#ffffff"} background={"#6B4380"} border={"#6B4380"} text={"Capturar Outra"} />
          <Button onClick={salvar} width={"120px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Salvar"} />
        </div>
      ) : (
          <div style={{display: "flex", justifyContent:"flex-end", width:"100%"}}>
            <Button onClick={capture} width={"100px"} color={"#ffffff"} background={"#6B4380"} border={"#6B4380"} text={"Capturar"} />
            <Button onClick={toggleFacingMode} width={"120px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Alternar Câmera"} />
          </div>
      )}
    </>
  );
};

export default WebcamCapture